.card-text-inside:hover{
    background-color: #f1f1f1;
}

.related{
    text-decoration: none;
    color: black;
    margin: 15px 0px;
    background-color: #f1f1f1;

}

.related:hover{
    background-color: #f6f6f6;
    color: blue;
}

.historyButton:hover{
    background-color: #f6f6f6;    
}

.historyButton{
    padding: 15px;
}

.ad-container{
    display: flex;
    justify-content: center;
    align-items:center;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1000,
}

.answerClick:hover{
    background-color: red;
    color:aqua
}