ul.tree, ul.tree ul {
    list-style-type: none;
    position: relative;
}

ul.tree ul {
    display: none;
}

ul.tree li {
    margin: 0;
    padding: 10px 5px;
    position: relative;
}

ul.tree li::before, ul.tree li::after {
    content: '';
    left: -20px;
    position: absolute;
    right: auto;
}

ul.tree li::before {
    border-left: 1px solid #000;
    bottom: 50px;
    height: 100%;
    top: 0;
    width: 1px;
}

ul.tree li::after {
    border-top: 1px solid #000;
    height: 20px;
    top: 20px;
    width: 25px;
}

ul.tree li span {
    border: 1px dotted #000;
    display: inline-block;
    padding: 3px 8px;
    text-decoration: none;
}

ul.tree li.parent_li > span {
    cursor: pointer;
}

ul.tree > li::before, ul.tree > li::after {
    border: 0;
}

ul.tree li:last-child::before {
    height: 20px;
}

ul.tree li.parent_li > span:hover, ul.tree li.parent_li > span:hover + ul li span {
    background: #eee;
    border: 1px solid #94a0b4;
    color: #000;
}
ul.tree li.parent_li span.time{
    display: inline-block;
    width: 100px;
    text-align: center;
    position:absolute;
    right: -2000;
}